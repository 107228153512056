/* eslint-disable no-undef */
const StringUtils = function () {

    const makeString = function (object) {
        if (object == null) return '';
        return '' + object;
    };

    const escapeRegExp = function (str) {
        // eslint-disable-next-line no-useless-escape
        return makeString(str).replace(/([.*+?^=!:${}()|[\]\/\\])/g, '\\$1');
    };

    const defaultToWhiteSpace = function (characters) {
        if (characters == null)
            return '\\s';
        else if (characters.source)
            return characters.source;
        else
            return '[' + escapeRegExp(characters) + ']';
    };

    return {

        trim: function (str, characters) {
            var nativeTrim = String.prototype.trim;
            str = makeString(str);
            if (!characters && nativeTrim) return nativeTrim.call(str);
            characters = defaultToWhiteSpace(characters);
            return str.replace(new RegExp('^' + characters + '+|' + characters + '+$', 'g'), '');
        },
        contains: function (str, searchStr) {
            if (str == null || searchStr == null) {
                return false;
            }
            return str.indexOf(searchStr) >= 0;
        },
        containsIgnoreCase: function (str, searchStr) {
            if (str == null || searchStr == null) {
                return false;
            }
            return StringUtils.contains(str.toUpperCase(), searchStr.toUpperCase());
        },
        startsWith: function (str, starts) {
            str = makeString(str);
            starts = makeString(starts);
            return str.indexOf(starts) === 0;
        },

        startsWithIgnoreCase: function (str, starts) {
            return StringUtils.startsWith(str.toUpperCase(), starts.toUpperCase());
        },

        endsWith: function (str, ends) {
            str = makeString(str);
            ends = makeString(ends);
            if (typeof position == 'undefined') {
                // noinspection JSUnresolvedVariable
                position = str.length - ends.length;
            } else {
                // noinspection JSUnresolvedVariable
                position = Math.min(toPositive(position), str.length) - ends.length;
            }
            return position >= 0 && str.indexOf(ends, position) === position;
        },
        endsWithIgnoreCase: function (str, ends) {
            return StringUtils.endsWith(str.toUpperCase(), ends.toUpperCase());
        },
        isEmpty: function (str) {
            return str == null || str.length === 0;
        },
        isNotEmpty: function (str) {
            return !StringUtils.isEmpty(str);
        },
        isBlank: function (str) {
            return str === null || str === undefined || (/^\s*$/).test(makeString(str));
        },
        isNotBlank: function (str) {
            return !StringUtils.isBlank(str);
        },
        substringBetween: function (str, open, close) {
            if (str == null || open == null || close == null) {
                return null;
            }
            var start = str.indexOf(open);
            if (start !== -1) {
                var end = str.indexOf(close, start + open.length);
                if (end !== -1) {
                    return str.substring(start + open.length, end);
                }
            }
            return null;
        },
        substringBefore: function (str, separator) {
            if (StringUtils.isEmpty(str) || separator == null) {
                return str;
            }
            if (separator.length === 0) {
                return "";
            }
            var pos = str.indexOf(separator);
            if (pos === -1) {
                return str;
            }
            return str.substring(0, pos);
        },
        substringAfter: function (str, separator) {
            if (StringUtils.isEmpty(str)) {
                return str;
            }
            if (separator == null) {
                return "";
            }
            var pos = str.indexOf(separator);
            if (pos === -1) {
                return "";
            }
            return str.substring(pos + separator.length);
        },
        substringBeforeLast: function (str, separator) {
            if (StringUtils.isEmpty(str) || StringUtils.isEmpty(separator)) {
                return str;
            }
            var pos = str.lastIndexOf(separator);
            if (pos === -1) {
                return str;
            }
            return str.substring(0, pos);
        },
        substringAfterLast: function (str, separator) {
            if (StringUtils.isEmpty(str)) {
                return str;
            }
            if (StringUtils.isEmpty(separator)) {
                return "";
            }
            var pos = str.lastIndexOf(separator);
            if (pos === -1 || pos === (str.length - separator.length)) {
                return "";
            }
            return str.substring(pos + separator.length);
        }
    };

}();


export default StringUtils;