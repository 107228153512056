<template>
    <div>
        <el-table v-loading="tableLoading" :data="tableData" border>
            <el-table-column label="ID" prop="id" align="center" width="50px"/>
            <el-table-column label="名称" prop="name" align="center" width="100px"/>
            <el-table-column label="排序(降序)" prop="sort" align="center" width="120px"/>
            <el-table-column label="操作" align="left">
                <template #default="scope">
                    <el-button @click="onEditClick(scope.$index, scope.row)">编辑</el-button>
                    <el-button type="success" @click="onAddClick">添加</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import {DubbingApis} from "@/api/dubbing";
import {ElMessage} from "element-plus";
import {watch} from "vue";

export default {
    name: "LanguageTabPage",
    props: {
        selected: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            tableLoading: false,
            tableData: [],
        }
    },

    mounted() {
        this.loadTableData();
    },

    // ===========================================
    //      生命周期
    // ===========================================

    methods: {
        loadTableData() {
            this.tableLoading = true;
            DubbingApis.getLanguageList().then(res => {
                this.tableLoading = false;
                if (res.code === 200) {
                    this.tableData = res.data;
                }
            }).catch(error => {
                this.tableLoading = false;
                ElMessage.error("查询失败1: " + error.toString());
            })
        },

        // ===========================================
        //      Editing
        // ===========================================

        onEditClick() {

        },


        // ===========================================
        //      Add
        // ===========================================

        onAddClick() {

        }


    }
}
</script>

<style scoped>

</style>