export default {
  model: {
    prop: 'visible',
    event: 'update:visible'
  },
  props: {
    visible: Boolean,
  },
  emits: ['update:visible', 'success'],
  computed: {
    internalVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },

  watch: {
    visible: {
      immediate: true,
      handler(newVal, oldVal) {
        if (!oldVal && newVal) {
          this.onShow();
        } else if (oldVal && !newVal) {
          this.onDismiss();
        }
      }
    }
  },

  methods: {

    show() {
      this.internalVisible = true;
    },

    dismiss() {
      this.internalVisible = false;
    },

    onShow() {

    },

    onDismiss() {

    }
  }
}