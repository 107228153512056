import {Apis} from "@/api/Apis";

export const TtsApis = {

    // startAudition(data) {
    //     return Apis.post("/admin/tts/audition", data)
    // },

    // ============================================
    // mark - 微软密钥管理
    // ============================================

    getAzureKeysList() {
        return Apis.get("/admin/tts/azure/keys/all")
    },

    resetAzureKeysList() {
        return Apis.get("/admin/tts/azure/keys/resetConfigList")
    },

    addAzureKey(data) {
        return Apis.post("/admin/tts/azure/keys/add", data)
    },

    updateAzureKey(data) {
        return Apis.post("/admin/tts/azure/keys/update", data)
    },

    deleteAzureKey(id) {
        const params = {
            id: id
        }
        return Apis.get("/admin/tts/azure/keys/delete", params)
    },

    resumeAzureKey(id) {
        const params = {
            id: id
        }
        return Apis.get("/admin/tts/azure/keys/resume", params)
    },

    // ===========================================
    //      内容审核开关
    // ===========================================

    getCensorEnable() {
        return Apis.get("/admin/tts/config/censorEnable")
    },

    setCensorEnable(enable) {
        let param = {
            enable: enable
        }
        return Apis.get("/admin/tts/config/updateCensorEnable", param);
    },

    // ===========================================
    //      Vip Limit
    // ===========================================

    getVipLimitation() {
        return Apis.get("/admin/tts/vip/limit/all")
    },

    updateVipLimitCell(req) {
        return Apis.post("/admin/tts/vip/limit/updateItem", req)
    },

    /**
     * 配音限制缓存是在内存里的，不是redis
     */
    refreshVipLimitCache() {
        return Apis.get("/admin/tts/vip/limit/refresh")
    },

    // ===========================================
    //      任务管理
    // ===========================================

    /**
     * 查询配音任务列表
     */
    getTaskList(page, pageSize) {
        let params = {
            page: page,
            pageSize: pageSize
        }
        return Apis.get("/admin/tts/taskList", params)
    },

    getUserTaskList(userId, page, pageSize) {
        let params = {
            userId: userId,
            page: page,
            pageSize: pageSize
        }
        return Apis.get("/admin/tts/userTaskList", params)
    },

    /**
     * 查看任务文案
     */
    getTaskContent(taskId) {
        let params = {
            taskId: taskId
        }
        return Apis.get("/admin/tts/taskContent", params)
    },

    /**
     * 重新执行任务
     */
    retryTask(taskId) {
        let params = {
            taskId: taskId
        }
        return Apis.get("/admin/tts/retryTask", params)
    },

    syncWorkVersion() {
        return Apis.get("/admin/tts/draft/syncVersion")
    }

}