<template>
  <el-dialog v-model="internalVisible" :title="editing ? '编辑' : '添加'">
    <el-form ref="form" :model="form" :rules="rules" label-width="100px">
      <el-form-item v-if="editing" label="ID">{{ form.id }}</el-form-item>
      <el-form-item label="Region" prop="region">
        <el-input v-model="form.region" placeholder="输入地区，不能为空"></el-input>
      </el-form-item>
      <el-form-item label="Key" prop="subscribeKey">
        <el-input v-model="form.subscribeKey" placeholder="输入密钥，不能为空"></el-input>
      </el-form-item>
      <el-form-item label="Weight">
        <el-input-number v-model="form.weight" placeholder="输入权重，越大优先级越高"></el-input-number>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form.remark" placeholder="输入备注，可为空"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="dismiss">取消</el-button>
      <el-button type="primary" @click="onCommit">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import BasicDialog from "@/views/base/BasicDialog";
import {TtsApis} from "@/api/tts-apis";

export default {
  name: "SubscribeKeyEditDialog",
  mixins: [BasicDialog],
  data() {
    return {
      editing: false,
      form: {
        id: null,
        region: null,
        subscribeKey: null,
        remark: null,
        weight: 0
      },
      rules: {
        region: [
          {required: true, message: '地区不能为空', trigger: 'blur'},
        ],
        subscribeKey: [
          {required: true, message: 'key不能为空', trigger: 'blur'},
        ]
      }
    }
  },
  computed: {

  },
  methods: {
    showEditing(data) {
      this.editing = true;
      this.form.id = data.id;
      this.form.region = data.region;
      this.form.weight = data.weight;
      this.form.remark = data.remark;
      this.form.subscribeKey = data.subscribeKey;
      this.internalVisible = true;
    },
    onCommit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.editing) {
            this.doEdit()
          } else {
            this.doAdd()
          }
        }
      })
    },
    doAdd() {
      const body = {
        region: this.form.region,
        subscribeKey: this.form.subscribeKey,
        weight: this.form.weight,
        remark: this.form.remark
      }
      TtsApis.addAzureKey(body).then(_ => {
        this.$message.success('添加成功！')
        this.dismiss()
        this.$emit('success')
      })
    },
    doEdit() {
      const body = {
        id: this.form.id,
        region: this.form.region,
        subscribeKey: this.form.subscribeKey,
        weight: this.form.weight,
        remark: this.form.remark
      }
      TtsApis.updateAzureKey(body).then(_ => {
        this.$message.success('修改成功！')
        this.dismiss()
        this.$emit('success')
      })
    }
  }
}
</script>

<style scoped>

</style>