<template>
  <el-container>
    <el-header>
      <common-page-title-label title="微软密钥"/>
      <common-add-button title="添加密钥" @click="onAddKey"/>
      <common-add-button title="刷新缓存" @click="onResetCache"/>
    </el-header>

    <el-main>
      <el-table :data="keyList" stripe border>
        <el-table-column label="ID" prop="id" width="60" align="center"/>
        <el-table-column label="地区" prop="region" width="120" align="center"/>
        <el-table-column label="密钥" prop="subscribeKey" width="320" align="center"/>
        <el-table-column label="备注" prop="remark" width="160" align="center"/>
        <el-table-column label="权重" prop="weight" width="60" align="center"/>
        <el-table-column label="状态" prop="status" width="60" align="center"/>
        <el-table-column label="操作">
          <template #default="scope">
            <el-row>
              <el-button size="small" @click="onItemAuditClick(scope.row)">试听</el-button>
              <el-button size="small" @click="onItemEditClick(scope.row)">编辑</el-button>
              <el-button
                v-if="scope.row.status === 0"
                size="small"
                type="danger"
                @click="onItemDeleteClick(scope.row)">
                删除
              </el-button>
              <el-button
                v-else
                size="small"
                type="success"
                @click="onItemResumeClick(scope.row)">
                恢复
              </el-button>
            </el-row>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
  </el-container>
  <subscribe-key-edit-dialog ref="editDialog" v-model:visible="editDialogVisible" @success="getAllKeys"/>
</template>

<script>
import CommonAddButton from "@/views/common/CommonAddButton.vue";
import CommonPageTitleLabel from "@/views/common/CommonPageTitleLabel.vue";
import {TtsApis} from "@/api/tts-apis";
import SubscribeKeyEditDialog from "@/views/dubbing/subscribeKey/SubscribeKeyEditDialog.vue";
import {Navigation} from "@/router/Navigation";
import {ToastUtil} from "@/utils/toast-util";

export default {
  name: "SubscribeKeyListPage",
  components: {SubscribeKeyEditDialog, CommonPageTitleLabel, CommonAddButton},
  data() {
    return {
      keyList: [],
      editDialogVisible: false,
    }
  },
  mounted() {
    this.getAllKeys();
  },
  methods: {
    getAllKeys() {
      TtsApis.getAzureKeysList().then(res => {
        this.keyList = res.data;
      })
    },
    onAddKey() {
      this.$refs.editDialog.show();
    },
    onResetCache() {
      TtsApis.resetAzureKeysList().then(_ => {
        this.$message.success('刷新成功！')
      })
    },
    onItemAuditClick(row) {
      Navigation.openInNewTab(this.$router, '/dubbing/anchor/add', {
        subscriptionId: row.id
      })
    },
    onItemEditClick(row) {
      this.$refs.editDialog.showEditing(row)
    },
    onItemDeleteClick(row) {
      this.$confirm('确定要删除吗？', '温馨提示').then(_ => {
        TtsApis.deleteAzureKey(row.id).then(res => {
          this.$message.success('已删除')
          this.getAllKeys()
        })
      })
    },
    onItemResumeClick(row) {
      this.$confirm('确定要恢复吗？', '温馨提示').then(_ => {
        TtsApis.resumeAzureKey(row.id).then(res => {
          this.$message.success('已恢复')
          this.getAllKeys()
        })
      })
    }
  }
}
</script>

<style scoped>

</style>