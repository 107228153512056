<template>
  <el-container>
    <el-header>
      <el-row>
        <common-page-title-label title="配音会员套餐"/>
        <!--<el-input v-model="searchUserId" style="width: 180px;" placeholder="输入用户ID"/>-->
        <!--<el-button type="primary" class="ml-16" @click="_onSearchClick">搜索</el-button>-->
        <!--<el-button type="primary" @click="_onSearchClearClick">清空</el-button>-->
        <!--<el-radio-group v-model="obtainType" @change="_onObtainTypeChange" >-->
        <!--    <el-radio-button label="-1">全部</el-radio-button>-->
        <!--    <el-radio-button label="0">自主购买</el-radio-button>-->
        <!--    <el-radio-button label="1">新人奖励</el-radio-button>-->
        <!--    <el-radio-button label="2">平台赠送</el-radio-button>-->
        <!--</el-radio-group>-->
      </el-row>
    </el-header>

    <el-main style="width: 100%;height: 100%;">
      <el-table :data="productList" border v-loading="this.loading">
        <el-table-column label="ID" prop="id" width="60" align="center" fixed="left"/>
        <el-table-column label="Name" prop="name" width="200" align="center" fixed="left"/>
        <el-table-column label="Code" prop="code" width="270" align="center"/>
        <el-table-column label="Title" prop="title" width="160" align="center"/>
        <el-table-column label="Level" prop="vipLevel" width="60" align="center"/>
        <el-table-column label="时长" prop="count" width="80" align="center">
          <template #default="scope">
            <div>{{ scope.row.count }}{{ scope.row.unit }}</div>
          </template>
        </el-table-column>
        <el-table-column label="原价" prop="originPrice" width="120" align="center"/>
        <el-table-column label="现价" prop="realPrice" width="120" align="center"/>
        <el-table-column label="内购价" prop="iapPrice" width="120" align="center"/>
        <el-table-column label="促销" prop="promotion" width="120" align="center"/>
        <el-table-column label="排序" prop="sort" width="60" align="center"/>
        <el-table-column label="操作" fixed="right">
          <template #default="scope">
            <el-button size="small" type="primary" @click="_onEditClick(scope.row)">编辑</el-button>
            <el-button v-if="scope.row.status === 0" size="small" type="danger" @click="_onDisableClick(scope.row)">
              下架
            </el-button>
            <el-button v-else size="small" type="success" @click="_onResumeClick(scope.row)">
              恢复
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
  </el-container>

  <tts-vip-product-edit-dialog ref="editDialog" v-model:visible="productEditDialogVisible" @change="_onProductChange"/>
</template>

<script>
import CommonPageTitleLabel from "@/views/common/CommonPageTitleLabel";
import {TtsVipApis} from "@/api/tts-vip-apis";
import TtsVipProductEditDialog from "@/views/vip/TtsVipProductEditDialog";
import {ToastUtil} from "@/utils/toast-util";

export default {
  name: "TtsVipProductManagerPage",
  components: {TtsVipProductEditDialog, CommonPageTitleLabel},
  data() {
    return {
      loading: false,
      productList: [],
      productEditDialogVisible: false,
    }
  },
  mounted() {
    this._loadProductList();
  },
  methods: {
    _loadProductList() {
      TtsVipApis.getProductList().then(res => {
        this.productList = res.data;
      }).catch(error => {

      })
    },
    _onDisableClick(itemData) {
      TtsVipApis.disableProduct(itemData.id).then(res => {
        this._loadProductList();
        ToastUtil.toast("修改成功")
      })
    },
    _onResumeClick(itemData) {
      TtsVipApis.enableProduct(itemData.id).then(res => {
        this._loadProductList();
        ToastUtil.toast("修改成功")
      })
    },
    _onEditClick(itemData) {
      this.$refs.editDialog.init(itemData);
      this.productEditDialogVisible = true;
    },

    _onProductChange() {
      this._loadProductList();
    }
  }
}
</script>

<style scoped>

</style>